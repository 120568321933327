import { SvgIcon } from './svg-icon';
import { SvgIconProps } from './svg-icon.d';
import { mergeProps } from 'solid-js';

const defaultProps: SvgIconProps = {
    height: 32,
    width: 85,
    fill: 'currentColor',
    viewBox: '0 0 85 32',
};

export const SiteLogo = (componentProps: SvgIconProps) => {
    const props = mergeProps(defaultProps, componentProps);

    return (
        <SvgIcon {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6064 9.44458C23.6911 14.199 17.8687 21.2624 17.4355 21.795H27.6064V9.44458Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.0382 26.9242C7.5306 26.3174 15.6898 16.2645 15.6898 16.2645C17.7303 13.7438 18.703 12.0463 18.703 9.23113C18.703 3.71112 14.9129 0 9.27006 0H0V5.04592H9.27006C11.7046 5.04592 13.2184 6.63209 13.2184 9.18886C13.2184 10.5839 12.8071 11.6104 11.6995 12.9705L0 27.2759V31.9657H27.6067V26.9242C27.6067 26.9242 7.83269 26.9242 7.0382 26.9242Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M65.6909 4.96228C66.3177 4.96228 73.366 4.96228 73.366 4.96228C76.5321 4.96228 78.4982 6.83006 78.4982 9.83442C78.4982 12.8157 76.5321 14.6666 73.366 14.6666C73.366 14.6666 66.3177 14.6666 65.6909 14.6666V4.96228ZM77.5523 19.0252C81.4157 17.6652 83.8877 14.266 83.8877 9.83452C83.8877 4.04556 79.6682 0 73.6265 0H60.2988V31.9657H65.6912C65.6912 31.9657 65.6912 20.3357 65.6912 19.6742H71.8078L78.8888 31.9657H84.9847L84.9996 31.9558L77.5523 19.0252Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M57.0411 0H51.9804C51.9804 0 51.9804 14.0161 51.9804 14.6803C51.3405 14.6803 39.469 14.6803 38.8291 14.6803C38.8291 14.0161 38.8291 0 38.8291 0H33.418V21.9188C33.9776 21.9188 36.1221 21.9188 36.1221 21.9188V26.9298C36.1221 26.9298 33.9776 26.9298 33.418 26.9298C33.418 27.5338 33.418 31.9657 33.418 31.9657H38.8291C38.8291 31.9657 38.8291 20.3775 38.8291 19.7111C39.469 19.7111 51.3405 19.7111 51.9804 19.7111C51.9804 20.3775 51.9804 31.9657 51.9804 31.9657H57.3922V0H57.0411Z" />
        </SvgIcon>
    );
};
