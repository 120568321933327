import { mergeProps } from 'solid-js';
import { ImageComponent } from './image-component';
import { ImageWordpress } from '../../types/shared';

type WrappedImageProps = {
    image: ImageWordpress;
    altText?: string;
    useFocusPoint?: boolean;
    roundCorners?: 'none' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'full';
    aspectRatio?: 'original' | 'wide' | 'portrait' | 'square' | 'newsListImage';
    renderWidth?: string;
    renderHeight?: string;
    width?: number | string;
    height?: number | string;
    classes?: string;
    topRounding?: boolean;
    noRoundCornersRightSide?: boolean;
};

const defaultProps = {
    renderWidth: '100%',
    renderHeight: 'auto',
};

const Svg = (componentProps: WrappedImageProps) => {
    return (
        <>
            <img src={componentProps.image.url} />
        </>
    );
};

export const WrappedImage = (componentProps: WrappedImageProps) => {
    const props = mergeProps(defaultProps, componentProps);

    if (componentProps.image.url.match(/\.svg$/)) {
        return (<Svg {...props} />);
    }

    return (
        <ImageComponent
            src={props.image.url}
            altText={props.altText ? props.altText : props.image.altText}
            ratio={props.image.ratio}
            focus={props.useFocusPoint && props.image.focus ? props.image.focus : undefined}
            roundCorners={props.roundCorners}
            aspectRatio={props.aspectRatio}
            renderWidth={props.renderWidth}
            renderHeight={props.renderHeight}
            width={props.width ? props.width : props.image.width}
            height={props.height ? props.height : props.image.height}
            classes={props.classes}
            topRounding={props.topRounding}
            noRoundCornersRightSide={props.noRoundCornersRightSide}
        />
    );
};
