import { Match, Switch, useContext } from 'solid-js';
import { Title } from 'solid-meta';
import { AppContext } from '../../app-context-provider/app-context-provider';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { ErrorCatcher } from '../../tools/error-catcher';
import { GET_PAGE_QUERY } from '../page/page';
import { SeoSettings } from '../seo-settings/seo-settings';

export const NotFound = () => {
    const { createCachedResource, page404url } = useContext(AppContext);
    const [page404Content] = createCachedResource(GET_PAGE_QUERY, () => ({ key: page404url }), true);

    
    return (
        <ErrorCatcher componentName='Not Found'>
            <Switch>
                <Match when={!page404Content() || !page404Content()?.page}>
                    <Title>{'Page not found - 24HR'}</Title>
                    <div>
                        <h1>{'Page not found'}</h1>
                    </div>
                </Match>

                <Match when={page404url}>
                    <SeoSettings seo={() => page404Content()?.page?.content?.seo} title={page404Content()?.page?.content?.post_title} />
                    <DynamicModuleGenerator
                        content={page404Content()?.page?.content.blocks}
                        permalink={page404Content()?.page?.content.permalink}
                        pageTitle={page404Content()?.page?.content.post_title}
                    />
                </Match>
            </Switch>
        </ErrorCatcher>
    );
};
