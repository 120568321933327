import { createContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

// TODO: many people do that with any, but is there a way to fix it?
const fullTailwindConfig: any = resolveConfig(tailwindConfig);

type PageContextProviderProps = {
    children?: any;
};

type ContextType = {
    tailwindConfig: any;
};
export const PageContext = createContext<ContextType>({
    tailwindConfig: fullTailwindConfig,
});

export function PageContextProvider(props: PageContextProviderProps) {
    const [tailwindConfig, setTailwindConfig] = createStore(fullTailwindConfig);

    return <PageContext.Provider value={{ tailwindConfig }}>{props.children}</PageContext.Provider>;
}
