import { Accessor, createSignal, For, onMount, Show } from 'solid-js';
import { Button } from '../../ui-components/button/button';
import { Text } from '../../ui-components/text/text';
import { FormProps, FieldType, FormValue, DropdownOption, MauticPostAction } from '../forms/forms.d';
import { ErrorCatcher } from '../../tools/error-catcher';
import { validate } from './validation';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Heading } from '../../ui-components/heading/heading';
import clsx from 'clsx';
import { FormInput } from './inputs/form-input';
import { FormTextArea } from './inputs/form-textarea';
import { ButtonColorName, ColorName } from '../../style/color-classes';

const getUniqueSlug = (field: FieldType) => {
    return `${field.extraSettings.name.value}`;
};

export const Forms = (props: FormProps) => {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const [loading, setLoading] = createSignal(false);
    const [error, setError] = createSignal('');
    const [success, setSuccess] = createSignal('');
    const [dropDownTextColor, setDropDownTextColor] = createSignal('text-secondary-300');

    const inputClasses = clsx(
        'py-5',
        'w-full',
        'rounded-s',
        'outline-none',
        'border-2',
        'border-solid',
        'border-transparent',
        'focus:bg-transparent',
        'focus:border-2',
        { 'focus:border-black bg-white opacity-80 text-neutral-900': props.variant === 'light' },
        { 'focus:border-white bg-secondary-800 text-secondary-100': props.variant === 'dark' },
        'focus:border-solid',
    );

    const labelIdleClasses = clsx(
        'absolute',
        'left-s',
        'top-s',
        'text-paragraphM',
        'overflow-hidden',
        'opacity-60',
        { 'text-secondary-100': props.variant === 'dark' },
        { 'text-neutral-900': props.variant === 'light' },
    );

    const getDropDownClasses = () =>
        clsx(
            'py-5',
            'w-full',
            'rounded-s',
            'outline-none',
            'border-2',
            'border-solid',
            'border-transparent',
            'focus:border-2',
            { 'focus:border-white bg-secondary-800': props.variant === 'dark' },
            'focus:border-solid',
            { 'bg-white focus:border-black opacity-80 text-neutral-900': props.variant === 'light' },
            dropDownTextColor(),
        );

    const labelFocusClasses = clsx('absolute', 'left-s', 'top-xxs', 'text-paragraphXS', 'text-secondary-100', 'opacity-60');

    onMount(async () => {
        if (!document.querySelector('#grecaptcha')) {
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.id = 'grecaptcha';
            scriptTag.src = 'https://www.google.com/recaptcha/enterprise.js?render=';
            document.head.appendChild(scriptTag);
        }

        const checkboxesFromForms = props.form.fields.filter((f) => f.type === 'checkboxgrp');
        setCheckboxes(checkboxesFromForms);
    });

    const setInitialValues = () => {
        if (!props.form?.fields) {
            return {};
        }
        const initialObj = props.form.fields?.reduce((obj: FormValue, field) => {
            if (field.label === 'customDropdown') {
                const uniqueSlug = getUniqueSlug(field);
                obj[uniqueSlug] = '';
            } else if (field.label === 'textArea') {
                const uniqueSlug = getUniqueSlug(field);
                obj[uniqueSlug] = '';
            } else {
                if (field.alias) obj[field.alias] = '';
            }
            return obj;
        }, {});
        return initialObj;
    };

    const [formValues, setFormValues] = createSignal<FormValue>(setInitialValues());
    const [checkboxes, setCheckboxes] = createSignal<Array<FieldType>>();

    const handleInputChange = (fieldSlug: string, fieldValue: any) => {
        const newFormValues = { ...formValues() };
        newFormValues[fieldSlug] = fieldValue;
        setFormValues(newFormValues);
        setDropDownTextColor('text-secondary-100');
    };

    const handleCheck = (fieldSlug: string) => {
        const newFormValues = { ...formValues() };
        newFormValues[fieldSlug] = !newFormValues[fieldSlug];
        setFormValues(newFormValues);
    };

    const handleSend = async () => {
        setLoading(true);
        setError('');
        const isFormValid = isValid();
        if (!isFormValid) {
            setLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            // for (const [key, value] of Object.entries(formValues())) {
            //     const stringValue = value instanceof String ? value : JSON.stringify(value);
            //     formData.append(`mauticform[${key}]`, stringValue.toString());
            // }
            for (const [key, value] of Object.entries(formValues())) {
                // Directly append strings, numbers, booleans, or stringify only objects
                const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;
                formData.append(`mauticform[${key}]`, stringValue?.toString() ?? '');
            }

            formData.append('mauticform[formId]', props.form.id);
            formData.append('mauticform[return]', props.form.return);
            formData.append('mauticform[formName]', props.form.name);
            formData.append('mauticform[messenger]', props.form.messenger);

            fetch('/postform', {
                method: 'POST',
                headers: {
                    'x-form-id': props.form.id,
                },
                body: formData,
            });
            setLoading(false);
            try {
                if (typeof (window as any)?.gtag === 'function') {
                    (window as any).gtag('event', 'form_submit', {
                        /* eslint-disable camelcase */
                        event_category: 'form',
                        event_label: props.form.name,                        
                        form_id: props.form.id,
                        /* eslint-enable camelcase */
                    });
                }
            }
            catch (e) {
                console.error('Error posting event to gtag', e);
            }

            if (props.form.postAction == MauticPostAction.Redirect) {
                navigate(props.form.postActionProperty);
            }

            setSuccess(props.form.postAction == MauticPostAction.Message ? props.form.postActionProperty : 'Formuläret skickades!');
            setFormValues(setInitialValues());
        } catch (e) {
            console.error('Error submitting the form', e);
            setError('Error submitting the form');
        }
    };

    const isValid = () => {
        const acceptPolicyField = props.form.fields.find((field: any) => field.type === 'checkboxgrp');
        if (acceptPolicyField) {
            if (acceptPolicyField.alias !== undefined) {
                const checked = formValues()[acceptPolicyField.alias];
                if (!checked) {
                    setError('Du måste godkänna villkoren');
                    return false;
                }
            }
        }
        const isFormValid = props.form.fields.every((field) => {
            const key = field.alias;
            if (key) {
                const value = formValues()[key];
                if (field.isRequired && !value) {
                    setError(`${field.label} är obligatoriskt`);
                    return false;
                }

                if (value === '') {
                    // If the value is an empty string, we don't want to validate it - we regard it as valid. If it was required we would've caught it before.
                    return true;
                }

                // If we have a value, we validate it.
                const { valid, errorMessage } = validate(key, value);
                setError(errorMessage);

                return valid;
            }
        });
        return isFormValid;
    };

    const renderPolicy = () => {
        const checkboxesFromForms = props.form.fields.filter((f) => f.type === 'checkboxgrp');
        setCheckboxes(checkboxesFromForms);
        <Show when={checkboxes()} fallback={<p>hej</p>}>
            <div class="">
                <For each={checkboxesFromForms}>
                    {(policy: FieldType, i: Accessor<number>) => (
                        <Checkbox
                            isChecked={formValues()[policy.alias]}
                            whenClicked={() => {
                                handleCheck(policy.alias);
                            }}
                            value="privacy-policy-consent"
                            name={policy.label}
                        />
                    )}
                </For>
            </div>
        </Show>;
        // if (checkboxes.length > 0) {
        //     setCheckboxes(checkboxes);
        //     return (
        //         <div class=''>
        //             <p>hej</p>
        //             <For each={checkboxes()}>
        //                 {(policy: FieldType, i: Accessor<number>) => (
        //
        //                     <Checkbox
        //                         isChecked={formValues()[policy.alias]}
        //                         whenClicked={() => {
        //                             handleCheck(policy.alias);
        //                         }}
        //                         value='privacy-policy-consent'
        //                         name={policy.label}
        //                     />
        //                 )}</For>
        //         </div>
        //
        //     );
        // } else {
        //     return;
        //
        // }
    };

    const renderDropdown = () => {
        const dropdowns = props.form.fields.find((f) => f.type === 'select') as any;
        if (dropdowns && dropdowns.properties?.list.list.length > 0) {
            const uniqueSlug = dropdowns.alias;
            const options: DropdownOption[] = dropdowns.properties.list.list
                .map((v: any) => v.value.trim())
                .map((v: string) => {
                    const label = v.charAt(0).toUpperCase() + v.slice(1);
                    return {
                        value: v,
                        text: label,
                    };
                });
            return (
                <>
                    <div class="w-full">
                        <select
                            class={'p-4 ' + getDropDownClasses()}
                            required={dropdowns.isRequired}
                            onChange={(e: any) => handleInputChange(uniqueSlug, e.currentTarget.value)}
                        >
                            {dropdowns.isRequired ? (
                                <option selected class={getDropDownClasses() + ' !text-secondary-900'} disabled value={dropdowns.label + '*'}>
                                    {dropdowns.label + '*'}
                                </option>
                            ) : (
                                <option selected class={getDropDownClasses() + ' text-secondary-300'} disabled value="">
                                    {dropdowns.label}
                                </option>
                            )}
                            {options.map((option) => {
                                return (
                                    <option class={getDropDownClasses() + ' !text-secondary-100'} value={option.value}>
                                        {option.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </>
            );
        } else {
            return;
        }
    };

    const productContactTitle = () => {
        const preText = 'product-contact-form-pre-text';
        const postText = 'product-contact-form-post-text';

        return `${preText + searchParams?.product} ${postText}`;
    };

    return (
        <ErrorCatcher componentName="Forms">
            <Show when={props.form.fields}>
                <div class="px-m mx-auto w-full pt-8" id="24hr-form">
                    <Show when={searchParams?.product}>
                        <Heading tag="h2" fontSize="xxl" align="center">
                            {productContactTitle()}
                        </Heading>
                    </Show>
                    <div class="gap-s mb-s tablet:flex-row flex flex-col ">
                        {props.form.fields.map((field) => {
                            if (field.alias === 'fornamn' || field.alias === 'efternamn' || field.alias === 'foretag') {
                                return (
                                    <FormInput
                                        inputClasses={inputClasses}
                                        labelIdleClasses={labelIdleClasses}
                                        labelFocusClasses={labelFocusClasses}
                                        label={field.label}
                                        required={field.isRequired}
                                        name={field.label}
                                        slug={field.alias}
                                        handleInputChange={handleInputChange}
                                        formValues={formValues}
                                    />
                                );
                            }
                        })}
                    </div>
                    <div class="gap-s mb-s tablet:flex-row flex flex-col ">
                        {props.form.fields.map((field) => {
                            if (field.alias === 'email') {
                                return (
                                    <FormInput
                                        inputClasses={inputClasses}
                                        labelIdleClasses={labelIdleClasses}
                                        labelFocusClasses={labelFocusClasses}
                                        label={field.label}
                                        name={field.label}
                                        required={field.isRequired}
                                        slug={field.alias}
                                        handleInputChange={handleInputChange}
                                        formValues={formValues}
                                    />
                                );
                            }
                        })}
                        {renderDropdown()}
                    </div>
                    {props.form.fields.map((field: any) => {
                        if (field.alias === 'kommentar') {
                            return (
                                <FormTextArea
                                    inputClasses={inputClasses}
                                    labelIdleClasses={labelIdleClasses}
                                    labelFocusClasses={labelFocusClasses}
                                    name={field.label}
                                    label={field.label}
                                    required={field.isRequired}
                                    slug={field.alias}
                                    handleInputChange={handleInputChange}
                                    formValues={formValues}
                                />
                            );
                        }
                    })}

                    <div class="mt-xs tablet:flex-row-reverse flex flex-col justify-between">
                        <Text color={ColorName.Secondary300} fontSize="xs" align="right">
                            * Obligatoriska fält
                        </Text>
                        <Show when={checkboxes()}>
                            <div class="w-full">
                                <For each={checkboxes()}>
                                    {(policy: FieldType) => (
                                        <Checkbox
                                            isChecked={formValues()[policy.alias ?? '']}
                                            whenClicked={() => {
                                                handleCheck(policy.alias ?? '');
                                            }}
                                            value="privacy-policy-consent"
                                            name={policy.label}
                                        />
                                    )}
                                </For>
                            </div>
                        </Show>
                    </div>

                    <Show when={error()} fallback={<div style={{ height: '22px' }}></div>}>
                        <p>{error()}</p>
                    </Show>

                    <Show when={loading()}>
                        <p>hej</p>
                    </Show>

                    <Show when={success()}>
                        <Heading tag="h4" align="center">
                            {success()}
                        </Heading>
                    </Show>
                    <Show when={!success()}>
                        <div class="flex w-full">
                            <Button
                                id='twofour-form-submit'
                                label="Skicka"
                                color={ButtonColorName.Primary}
                                variant="solid"
                                buttonSize={'large'}
                                onClick={handleSend}
                                arrow={true}
                                fullWidth={true}
                            />
                        </div>
                    </Show>
                </div>
            </Show>
        </ErrorCatcher>
    );
};

Forms.parseProps = (atts: any) => {
    const { form, variant } = atts;
    const commonAtss = {
        form,
        variant,
    };

    return commonAtss;
};
