import { ErrorCatcher } from '../../tools/error-catcher';
import { useContext } from 'solid-js';
import { getImageServiceSrc } from '../../tools/get-image-src';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ImageWordpress } from '../../types/shared';
import dayjs from 'dayjs';
import { CategoryType } from '../blog-post/blog-post-intro.d';

const getImageObject = (imageObject: ImageWordpress) => {
    if (imageObject === null) {
        return null;
    }
    const imageServiceSrc = getImageServiceSrc(imageObject);
    const width = imageObject.width;
    const height = imageObject.height;

    return {
        '@type': 'ImageObject',
        '@id': imageServiceSrc,
        url: imageServiceSrc,
        height: height,
        width: width,
    };
};

const getAuthorObject = (author: any) => {
    return {
        '@type': 'Person',
        name: author?.visibleName,
        image: author?.profilePicture ? getImageObject(author?.profilePicture) : null,
    };
};

const getPublisherObject = () => {
    const AppState = useContext(AppContext);
    return {
        '@type': 'Organization',
        '@id': 'https://www.24hr.se',
        name: '24HR',
        logo: AppState?.siteLogo ? getImageObject(AppState?.siteLogo) : null,
    };
};

const articleSchema = (content: any) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        url: 'https://www.24hr.se' + content?.permalink,
        publisher: getPublisherObject(),
        name: content?.post_title,
        description: content?.list_description,
        articleBody: content?.preamble,
        datePublished: dayjs(new Date(content?.date)).format('YYYY-MM-DD'),
        image: [getImageServiceSrc(content?.featured_image)],
    };
};

const newsSchema = (content: any) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        url: 'https://www.24hr.se' + content?.permalink,
        publisher: getPublisherObject(),
        image: [getImageServiceSrc(content?.featured_image)],
        headline: content?.post_title,
        mainEntityOfPage: 'https://www.24hr.se' + content?.permalink,
        articleBody: content?.preamble,
        datePublished: dayjs(new Date(content?.date)).format('YYYY-MM-DD'),
    };
};

const blogSchema = (content: any) => {
    const title = content?.post_title;
    const preamble = content?.preamble;
    const abstract = content?.list_description;

    const categories = content?.categories?.map((category: CategoryType) => category?.name);

    return {
        '@context': 'https://schema.org/',
        '@type': 'BlogPosting',
        url: 'https://www.24hr.se' + content?.permalink,
        name: title,
        headline: title,
        description: preamble,
        abstract: abstract,
        image: getImageObject(content?.featured_image),
        publisher: getPublisherObject(),
        author: getAuthorObject(content?.author),
        datePublished: dayjs(new Date(content?.date)).format('YYYY-MM-DD'),
        keywords: categories,
    };
};

const organizationSchema = (content: any) => {
    return {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        email: 'malmo@24hr.se',
        telephone: '+46406020700',
        address: {
            '@type': 'PostalAddress',
            addressLocality: 'Malmö, Sweden',
            postalCode: '21139',
            streetAddress: 'Gustav Adolfs torg 8b',
        },
        contactPoint: [
            {
                '@type': 'ContactPoint',
                telephone: '+46406020700',
                areaServed: ['SV', 'DK', 'NO'],
                availableLanguage: ['Swedish', 'English', 'Danish', 'Norwegian'],
            },
        ],
    };
};

const getSchemaContent = (content: any) => {
    let schemaContent = '';
    if (content?.type === 'blog') {
        schemaContent = JSON.stringify(blogSchema(content));
    } else if (content?.type === 'news') {
        schemaContent = JSON.stringify(newsSchema(content));
    } else if (content?.type === 'project') {
        schemaContent = JSON.stringify(articleSchema(content));
    } else if (content?.permalink?.startsWith('/kontakt') || content?.permalink?.startsWith('/om-oss')) {
        schemaContent = JSON.stringify(organizationSchema(content));
    }
    return schemaContent;
};

export const Schema = (props: any) => {
    return (
        <ErrorCatcher componentName="Schema">
            <script type="application/ld+json">{getSchemaContent(props.content)}</script>
        </ErrorCatcher>
    );
};
