import { For, Setter, Show, createEffect, createSignal, useContext } from 'solid-js';
import clsx from 'clsx';
import { gql } from 'graphql-request';
import { CloseIcon } from '../icons-library/close';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { SearchResult, getPostType } from './search-result';

const SEARCH_QUERY = gql`
    query Search($searchInput: search_SearchInput!, $idXAtomsInput: search_IdxAtomsInput!) {
        searchResult: search(searchInput: $searchInput, idXAtomsInput: $idXAtomsInput) {
            hits
            hitsPerPage
            page
            totalPages
            totalHits
        }
    }
`;

export const MobileSearch = (props: MobileSearchProps) => {

    const { siteInfo, graphQlClient } = useContext(AppContext);
    const [searchTerm, setSearchTerm] = createSignal('');
    const [debounce, setDebounce] = createSignal<ReturnType<typeof setTimeout>>();
    const [totalHits, setTotalHits] = createSignal(0);
    const [hits, setHits] = createSignal<Hit[]>([]);
    const [activeFilter, setActiveFilter] = createSignal('Alla');
    const [uniqueFilters, setUniqueFilters] = createSignal<string[]>([]);
    const [isInitialSearch, setIsInitialSearch] = createSignal(true);

    const { siteId, target } = siteInfo;

    if (!siteId || !target) {
        return <div>No siteId / target was found..</div>;
    }

    const search = async (variables: any) => {
        console.log('do');
        const res = await graphQlClient(SEARCH_QUERY, variables);
        setTotalHits(res.searchResult.totalHits);
        setHits(res.searchResult.hits);
        if (isInitialSearch()) {
            const receivedFilters = res.searchResult.hits
                .map((hit: Hit) => hit.type)
                .filter((value: string, index: number, self: any) => self.indexOf(value) === index);
            setUniqueFilters(receivedFilters);
            setIsInitialSearch(false);
        }
    };

    const toggleMenu = () => {
        if (props?.setMobileMenuActive) {
            const body = document.querySelector('body');
            body?.classList.toggle('fixed');
            props?.setMobileMenuActive?.(false);
        }
        props.setSearchActive('inactive');
    };

    const handleSearchTermChange = (e: Event) => {
        const valueTarget = e.target as HTMLInputElement;
        setSearchTerm(valueTarget.value);
        setIsInitialSearch(true);
        clearTimeout(debounce());
        const timeout = setTimeout(() => {
            const searchTermValue = valueTarget.value; //searchTerm();
            const filter = activeFilter();
            if (searchTermValue.trim()) {
                const variables = {
                    searchInput: {
                        searchString: searchTermValue,
                        offset: 0,
                        limit: 20,
                        hitsPerPage: 20,
                        page: 1,
                        matchesPosition: false,
                        highlight: true,
                        ...(filter !== 'Alla' && { filter }),
                    },
                    idXAtomsInput: {
                        siteId,
                        target,
                    },
                };
                search(variables);
            }
        }, 400);
        setDebounce(timeout);
    };

    const getFilteredPosts = () => {
        return hits().filter(hit => activeFilter() === 'Alla' || activeFilter() === hit.type);
    };

    const baseButtonClasses = 'rounded-xl border border-black px-3 py-1 font-medium capitalize text-black text-paragraphXS';
    const activeButtonClasses = 'bg-neutral-800 text-white';

    return (
        <div
            data-identifier="mobile-search-menu"
            class="text-secondary-600 tablet:h-full z-50 flex  h-screen w-full flex-col rounded-l bg-white p-4"
        >
            <button
                aria-label="Stäng meny"
                class="justify-content align-items tablet:pt-4 tablet:pr-4 absolute right-0 top-0 flex px-3 pr-10 pt-10 "
                onClick={toggleMenu}
            >
                <CloseIcon fill="black" class="transition-opacity duration-200 hover:opacity-50" />
            </button>
            <p class="text-paragraphXL mb-2 mt-6 px-3 font-bold text-black">Hitta något bra!</p>
            <div class="relative mb-6 mt-1 w-full px-3">
                <input
                    value={searchTerm()}
                    placeholder="Sök här..."
                    onInput={handleSearchTermChange}
                    class="bg-secondary-100 text-secondary-900 placeholder:text-secondary-400 h-12 w-full rounded-s pl-4 outline-0"
                    type="text"
                />
            </div>
            <Show when={searchTerm()}>
                <div class="mt-2 flex flex-col px-3 text-black">
                    <p class="text-paragraphS">
                        <span class="text-neutral-500">Hittade:</span> {searchTerm() ? totalHits() : 0} träffar
                    </p>
                    <div class="mt-2 flex flex-nowrap space-x-2 xoverflow-scroll">
                        <button onClick={() => setActiveFilter('Alla')} class={clsx({ [activeButtonClasses]: activeFilter() === 'Alla' }, baseButtonClasses)}>
                            {'Alla'}
                        </button>
                        <For each={uniqueFilters()}>
                            {(type) => (
                                <button
                                    class={clsx({ [activeButtonClasses]: activeFilter() === type }, baseButtonClasses)}
                                    onClick={() => setActiveFilter(type)}
                                >
                                    {getPostType(type)}
                                </button>
                            )}
                        </For>
                    </div>
                </div>

                <div class="mt-8 flex flex-col space-y-4 overflow-y-scroll last:pb-10">
                    <For each={getFilteredPosts()}>
                        {(hit) => (<SearchResult href={hit.url} postTitle={hit.postTitle} postType={hit.type} postContent={hit.texts} />) }
                    </For>
                </div>
            </Show>
        </div>
    );
};

type Hit = {
    objectID: string;
    url: string;
    type: string;
    tags: string[];
    postTitle: string;
    texts: string;
    _formatted: {
        objectID: string;
        url: string;
        type: string;
        tags: string[];
        postTitle: string;
        texts: string;
    };
};

type MobileSearchProps = {
    setMobileMenuActive?: Setter<boolean>;
    setSearchActive: Setter<'init' | 'active' | 'inactive'>;
};
